<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title color="primary"><h2 class="mb-0 ion-text-center">Account deleted</h2></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <div class="ion-padding horizontally-centered ion-text-center ion-margin-bottom">
        Sorry to see you go!
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';

export default {
  name: 'Bye',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent
  }
};
</script>
